import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorEvent } from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { EventHint } from '@sentry/react';
import { isAxiosError } from 'axios';

const environment = import.meta.env.VITE_V2_APP_ENV;

const handleSentryBeforeSend = (event: ErrorEvent, hint: EventHint) => {
  const { originalException: error } = hint;
  if (isAxiosError(error)) {
    console.log('error: ', error);
    const statusCode = error.response?.status || 'Unknown';
    const url = error.config?.url || 'Unknown URL';
    event.message = error.response?.data;
    if (event.exception?.values && event.exception.values[0]) {
      event.exception.values[0].type = `${url}`;
      event.exception.values[0].value = `Status code - ${statusCode}`;
    }
  }
  return event;
};

if (environment === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ignoreErrors: ["Can't unpublish stream, haven't joined yet!"],
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration(),
    ],
    beforeSend: handleSentryBeforeSend,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/api\.app\.fixzy\.ai/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
